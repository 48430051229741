import { GetterTree } from 'vuex';
import { UserState, RootState } from '@/store/models';

const getters: GetterTree<UserState, RootState> = {
  profile: (state: UserState) => {
    return state.profile;
  },


  isAuthenticated: (state: UserState) => {
    return state.isAuthenticated;
  },
};

export default getters;
