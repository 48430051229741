
import Vue from 'vue';
import Vuex, { StoreOptions } from 'vuex';
import { RootState } from './models';
import { user } from './user/index';
import { personalHistory } from './personal-history/index';

Vue.use(Vuex);

const store: StoreOptions<RootState> = {
    state: {
        version: '1.0.0',
    },
    modules: {
        user,
        personalHistory,
    },
};

export default new Vuex.Store<RootState>(store);
