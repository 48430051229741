


















































































































import { Component, Vue, Mixins } from 'vue-property-decorator';
import { State, Action, Getter, Mutation } from 'vuex-class';
import Navigation from './views/Navigation.vue';
import Footer from './components/Footer.vue';
import { BaseVue } from './mixins';
import { Profile } from './store/models';

@Component({
  components: { Navigation, Footer },
})
export default class App extends Mixins(BaseVue) {
  @Getter('profile', { namespace: 'user' })
  public profile!: Profile;
  @Getter('isAuthenticated', { namespace: 'user' })
  public isAuthenticated!: boolean;

  public isDrawerShown = false;
  @Mutation('changeProfile', { namespace: 'user' })
  private changeProfile!: (profile: Profile) => void;
  @Mutation('changeAuthStatus', { namespace: 'user' })
  private changeAuthStatus!: (isAuthenticated: boolean) => void;

  public async created() {
    this.$firebaseService.observeUser(user => this.changeProfile(user));
    this.$firebaseService.onAuthStateChanged(user => {
      this.changeAuthStatus(!!user);
    });

    switch (navigator.language) {
      case 'ja':
        this.$router.replace({ query: { lang: 'ja' } });
        return;
      default:
        return;
    }
  }
}
