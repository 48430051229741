import { GetterTree } from 'vuex';
import { PersonalHistoryState, RootState } from '@/store/models';

const getters: GetterTree<PersonalHistoryState, RootState> = {
  educationHistory: (state: PersonalHistoryState) => {
    return state.educationHistory;
  },

  academicPositions: (state: PersonalHistoryState) => {
    return state.academicPositions;
  },

  publications: (state: PersonalHistoryState) => {
    return state.publications;
  },

  talks: (state: PersonalHistoryState) => {
    return state.talks;
  },

  grants: (state: PersonalHistoryState) => {
    return state.grants;
  },

  awards: (state: PersonalHistoryState) => {
    return state.awards;
  },

  memberships: (state: PersonalHistoryState) => {
    return state.memberships;
  },

  allNews: (state: PersonalHistoryState) => {
    return state.allNews;
  },
};

export default getters;
