import { Module } from 'vuex';
import { PersonalHistoryState, RootState } from '@/store/models';
import getters from './getters';
import actions from './actions';
import mutations from './mutations';

const state: PersonalHistoryState = {
  educationHistory: [],
  academicPositions: [],
  publications: [],
  talks: [],
  grants: [],
  awards: [],
  memberships: [],
  allNews: [],
};

export const personalHistory: Module<PersonalHistoryState, RootState> = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
