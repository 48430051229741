



























































































import { Component, Vue, Prop, Mixins } from 'vue-property-decorator';
import {
  Publication,
  Talk,
  Award,
  Grant,
  Membership,
  Categories,
} from '@/store/models';
import { BaseVue } from '@/mixins';

type CategoriesList = Publication[] | Talk[] | Grant[] | Award[] | Membership[];

function arePublications(arg: CategoriesList): arg is Publication[] {
  return arg.length !== 0 && 'arXiv' in arg[0];
}

function areTalks(arg: CategoriesList): arg is Talk[] {
  return arg.length !== 0 && 'talk' in arg[0];
}

function areGrants(arg: CategoriesList): arg is Grant[] {
  return arg.length !== 0 && 'grant' in arg[0];
}

function areAwards(arg: CategoriesList): arg is Award[] {
  return arg.length !== 0 && 'award' in arg[0];
}

function areMemberships(arg: CategoriesList): arg is Membership[] {
  return arg.length !== 0 && 'membership' in arg[0];
}

@Component
export default class Event extends Mixins(BaseVue) {
  @Prop()
  public events!: CategoriesList;
  @Prop({ default: false })
  public isEditorActive!: boolean;

  public get eventsByDate() {
    // TODO Improve
    // external links always contains at lest a link that might be empty: In that case we don't need to show it
    //@ts-ignore
    let normalizedEvents = this.events.map(e => ({
      ...e,
      //@ts-ignore
      externalLinks: e.externalLinks.filter(l => l !== ''),
    })) as CategoriesList;

    return arePublications(normalizedEvents)
      ? normalizedEvents.reduce(
          (acc: { [date: string]: Publication[] }, event) => {
            const year = event.on ? event.on.toDate().getFullYear() : '9999';
            acc[year] = acc[year] ? [...acc[year], event] : [event];
            return acc;
          },
          {}
        )
      : areTalks(normalizedEvents)
      ? normalizedEvents.reduce((acc: { [date: string]: Talk[] }, event) => {
          const year = event.on ? event.on.toDate().getFullYear() : '9999';
          acc[year] = acc[year] ? [...acc[year], event] : [event];
          return acc;
        }, {})
      : areGrants(normalizedEvents)
      ? normalizedEvents.reduce((acc: { [date: string]: Grant[] }, event) => {
          const year = event.on ? event.on.toDate().getFullYear() : '9999';
          acc[year] = acc[year] ? [...acc[year], event] : [event];
          return acc;
        }, {})
      : areAwards(normalizedEvents)
      ? normalizedEvents.reduce((acc: { [date: string]: Award[] }, event) => {
          const year = event.on ? event.on.toDate().getFullYear() : '9999';
          acc[year] = acc[year] ? [...acc[year], event] : [event];
          return acc;
        }, {})
      : normalizedEvents.reduce(
          (acc: { [date: string]: Membership[] }, event) => {
            const year = event.on ? event.on.toDate().getFullYear() : '9999';
            acc[year] = acc[year] ? [...acc[year], event] : [event];
            return acc;
          },
          {}
        );
  }

  public transform(event: Categories) {
    // TODO Not Sure why it works in real-time (Maybe it would be better to change the approach)
    const en = {
      headline: event.headline || event.headlineJa,
      subhead: event.subhead || event.subheadJa,
    };
    const ja = {
      headline: event.headlineJa || event.headline,
      subhead: event.subheadJa || event.subhead,
    };
    return { en, ja }[this.$language];
  }
}
