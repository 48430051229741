









































































































































































import { Component, Vue, Prop, Model, Mixins } from 'vue-property-decorator';
import * as firebase from 'firebase';
import { BaseVue } from '@/mixins';
import {
  AcademicPosition,
  Education,
  isEducation,
  Research,
} from '../store/models';

@Component
export default class ResearchEditorModal extends Mixins(BaseVue) {
  @Model('input')
  public isModalShown!: boolean;

  @Prop({ required: true })
  public research!: Research;
}
