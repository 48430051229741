













































import { Component, Vue, Prop, Model, Mixins } from 'vue-property-decorator';
import * as firebase from 'firebase';
import { BaseVue } from '@/mixins';
import { Getter } from 'vuex-class';
import {
  Profile,
  AcademicPosition,
  Education,
  isEducation,
} from '@/store/models';
import pdfUtils from '@/utils/pdf';
//@ts-ignore
import pdfMake from '@/pdfmake/index';

@Component
export default class PdfEditor extends Mixins(BaseVue) {
  @Getter('profile', { namespace: 'user' })
  public profile!: Profile;
  public pdfHeader: string = '';
  public ready = false;
  private timeout?: number;

  @Getter('isAuthenticated', { namespace: 'user' })
  public isAuthenticated!: boolean;

  public onHeaderChange(value: string) {
    if (this.timeout) clearTimeout(this.timeout);
    this.timeout = setTimeout(() => {
      this.$firebaseService.modifyPdfHeader(value);
    }, 500);
  }

  public async created() {
    const academicPositions = await this.$firebaseService.getAcademicPositions();
    const education = await this.$firebaseService.getEducationHistory();
    const publications = await this.$firebaseService.getPublications();
    const talks = await this.$firebaseService.getTalks();
    const grants = await this.$firebaseService.getGrants();
    const awards = await this.$firebaseService.getAwards();
    const memberships = await this.$firebaseService.getMemberships();
    const header = await this.$firebaseService.obtainPdfHeader(headline => {
      this.pdfHeader = headline;
      const pdfContent = pdfUtils.generateContent([
        pdfUtils.generateHeaderContent(headline),
        ...pdfUtils.generateEducationContent.call(
          this,
          academicPositions,
          education
        ),
        ...pdfUtils.generateEventContent(publications, talks, grants, awards, memberships),
      ]);

      const pdfDocGenerator = pdfMake.createPdf(pdfContent);
      pdfDocGenerator.getDataUrl((dataUrl: string) => {
        const iframe = this.$el.querySelector('#iframe')! as HTMLIFrameElement;
        iframe.src = dataUrl;
        this.ready = true;
      });
    });
  }
}
