import Vue from 'vue';
import Router from 'vue-router';
import Home from './views/Home.vue';
import Research from './views/Research.vue';
import Contact from './views/Contact.vue';
import CurriculumVitae from './views/CurriculumVitae.vue';
import ProfileEditor from './views/ProfileEditor.vue';
import EventEditor from './views/EventEditor.vue';
import CurriculumVitaeEditor from './views/CurriculumVitaeEditor.vue';
import About from './views/About.vue';

Vue.use(Router);

export default new Router({
  mode: 'history',
  routes: [
    {
      path: '/ ',
      name: 'home',
      component: Home,
    },
    {
      path: '/curriculum-vitae',
      name: 'curriculum-vitae',
      component: CurriculumVitae,
    },
    {
      path: '/research',
      name: 'research',
      component: Research,
    },
    {
      path: '/contact',
      name: 'contact',
      component: Contact,
    },
    {
      path: '/iam',
      name: 'iam',
      component: ProfileEditor,
    },
    {
      path: '/event-editor',
      name: 'event-editor',
      component: EventEditor,
    },
    {
      path: '/cv-editor',
      name: 'cv-editor',
      component: CurriculumVitaeEditor,
    },
    {
      path: '/about',
      name: 'about',
      component: About,
    },
    {
      path: '/pdf',
      name: 'pdf',
      component: () =>
        import(/* webpackChunkName: "about" */ './views/PdfEditor.vue'),
    },
    {
      path: '/privacy',
      name: 'privacy',
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () =>
        import(/* webpackChunkName: "about" */ './views/Privacy.vue'),
    },
    {
      path: '**',
      redirect: '/ ',
    },
  ],
});
