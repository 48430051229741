var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-layout',{attrs:{"column":""}},[_c('v-flex',{attrs:{"pa-2":""}},[_c('v-card',{staticClass:"mx-auto",attrs:{"max-width":"1100"}},[_c('v-card-title',{staticClass:"white--text primary darken-2"},[_c('span',{staticClass:"title"},[_vm._v(_vm._s(_vm.$translations.academicPositions))])]),_c('v-card-text',{staticClass:"py-0"},[_c('v-timeline',{attrs:{"dense":""}},[_c('v-slide-x-reverse-transition',{attrs:{"group":"","hide-on-leave":""}},_vm._l((_vm.academicPositions),function(academicPosition,index){return _c('v-timeline-item',{key:("academic-position-" + index),attrs:{"color":"primary darken-2","small":"","fill-dot":""}},[_c('v-alert',{staticClass:"white--text effects",attrs:{"value":"","color":"primary darken-2"}},[_c('v-list-item',{staticStyle:{"user-select":"auto"},attrs:{"dark":""}},[_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"text-wrap body-2 font-weight-bold",staticStyle:{"word-break":"break-word"},domProps:{"textContent":_vm._s(_vm.transform(academicPosition).headline)}}),_c('v-list-item-content',{staticClass:"caption",staticStyle:{"display":"contents","word-break":"break-word"},domProps:{"textContent":_vm._s(_vm.transform(academicPosition).subhead)}}),_c('v-list-item-subtitle',{staticClass:"caption",domProps:{"textContent":_vm._s(
                          ((_vm.$parseTimestamp(
                            academicPosition.from
                          )) + " — " + (_vm.$parseTimestamp(academicPosition.to)))
                        )}})],1),(_vm.isAuthenticated)?_c('v-list-item-action',{staticClass:"ma-0"},[_c('v-menu',{attrs:{"offset-y":"","left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                        var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"dark":"","icon":""}},on),[_c('v-icon',[_vm._v("more_vert")])],1)]}}],null,true)},[_c('v-list',[_c('v-list-item',{on:{"click":function($event){return _vm.onEditClick(academicPosition)}}},[_c('v-list-item-action',[_c('v-icon',{attrs:{"color":"grey darken-1"}},[_vm._v("edit")])],1),_c('v-list-item-title',{staticClass:"grey--text text--darken-2"},[_vm._v("Edit")])],1),_c('v-list-item',{on:{"click":function($event){return _vm.onRemoveClick(academicPosition)}}},[_c('v-list-item-action',[_c('v-icon',{attrs:{"color":"grey darken-1"}},[_vm._v("delete")])],1),_c('v-list-item-title',{staticClass:"grey--text text--darken-2"},[_vm._v("Remove")])],1)],1)],1)],1):_vm._e()],1)],1)],1)}),1)],1)],1)],1)],1),_c('v-flex',{attrs:{"pa-2":""}},[_c('v-card',{staticClass:"mx-auto",attrs:{"max-width":"1100"}},[_c('v-card-title',{staticClass:"white--text blue-grey darken-2"},[_c('span',{staticClass:"title"},[_vm._v(_vm._s(_vm.$translations.education))])]),_c('v-card-text',{staticClass:"py-0"},[_c('v-timeline',{attrs:{"dense":""}},[_c('v-slide-x-reverse-transition',{attrs:{"group":"","hide-on-leave":""}},_vm._l((_vm.educationHistory),function(education,index){return _c('v-timeline-item',{key:("education-" + index),attrs:{"color":"blue-grey darken-2","small":"","fill-dot":""}},[_c('v-alert',{staticClass:"white--text",attrs:{"value":"","color":"blue-grey darken-2 effects"}},[_c('v-list-item',{staticStyle:{"user-select":"auto"},attrs:{"dark":""}},[_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"text-wrap body-2 font-weight-bold",staticStyle:{"word-break":"break-word"},domProps:{"textContent":_vm._s(_vm.transform(education).headline)}}),_c('v-list-item-content',{staticClass:"caption",staticStyle:{"display":"contents","word-break":"break-word"},domProps:{"textContent":_vm._s(_vm.transform(education).subhead)}}),_c('v-list-item-subtitle',{staticClass:"caption",domProps:{"textContent":_vm._s(
                          ((_vm.$parseTimestamp(
                            education.from
                          )) + " — " + (_vm.$parseTimestamp(education.to)))
                        )}})],1),(_vm.isAuthenticated)?_c('v-list-item-action',{staticClass:"ma-0"},[_c('v-menu',{attrs:{"offset-y":"","left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                        var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"dark":"","icon":""}},on),[_c('v-icon',[_vm._v("more_vert")])],1)]}}],null,true)},[_c('v-list',[_c('v-list-item',{on:{"click":function($event){return _vm.onEditClick(education)}}},[_c('v-list-item-action',[_c('v-icon',{attrs:{"color":"grey darken-1"}},[_vm._v("edit")])],1),_c('v-list-item-title',{staticClass:"grey--text text--darken-2"},[_vm._v("Edit")])],1),_c('v-list-item',{on:{"click":function($event){return _vm.onRemoveClick(education)}}},[_c('v-list-item-action',[_c('v-icon',{attrs:{"color":"grey darken-1"}},[_vm._v("delete")])],1),_c('v-list-item-title',{staticClass:"grey--text text--darken-2"},[_vm._v("Remove")])],1)],1)],1)],1):_vm._e()],1)],1)],1)}),1)],1)],1)],1)],1)],1),_c('CurriculumVitaeEditorModal',{attrs:{"educationOrAcademicPosition":_vm.educationOrAcademicPosition},on:{"educationOrAcademicPositionChange":_vm.onEducationOrAcademicPositionChange,"modify":_vm.onModifyClick,"close":_vm.onCloseClick},model:{value:(_vm.isCurriculumVitaeModalOpen),callback:function ($$v) {_vm.isCurriculumVitaeModalOpen=$$v},expression:"isCurriculumVitaeModalOpen"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }