























































import { Component, Vue, Mixins } from 'vue-property-decorator';
import { BaseVue } from '../mixins';

@Component
export default class Footer extends Mixins(BaseVue) {}
