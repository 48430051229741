import { ActionTree } from 'vuex';
import { RootState, UserState, Profile } from '@/store/models';

const actions: ActionTree<UserState, RootState> = {
  add: async ({ commit }, profile: Profile) => {
    commit('changeProfile', profile);
    return profile;
  },

  remove: async ({ commit }, id: string) => {
    if (await Promise.resolve(true)) {
      commit('remove', id);
      return true;
    }
    return false;
  },
};

export default actions;
