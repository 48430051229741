
































































































































import { Component, Vue, Prop, Model, Mixins } from 'vue-property-decorator';
import * as firebase from 'firebase';
import { BaseVue } from '@/mixins';
import { AcademicPosition, Education, isEducation } from '../store/models';

@Component
export default class CurriculumVitaeEditorModal extends Mixins(BaseVue) {
  @Model('input')
  public isModalShown!: boolean;
  @Prop()
  public educationOrAcademicPosition!: AcademicPosition | Education;
  public isDatepickerFromOpen: boolean = false;
  public isDatepickerToOpen: boolean = false;

  public allowedDates(constraint: '>=' | '<=', targetDate: string) {
    return (date: string) =>
      constraint === '>='
        ? new Date(date) >= new Date(targetDate)
        : new Date(date) <= new Date(targetDate);
  }

  public get dateFrom() {
    return this.educationOrAcademicPosition.from
      ? this.parseDate(this.educationOrAcademicPosition.from.toDate())
      : this.parseDate(new Date());
  }

  public get dateTo() {
    return this.educationOrAcademicPosition.to
      ? this.parseDate(this.educationOrAcademicPosition.to.toDate())
      : this.parseDate(new Date());
  }

  public onDateFromSelect(value: string) {
    const date = new Date(value);
    const from = firebase.firestore.Timestamp.fromDate(date);
    this.$emit('educationOrAcademicPositionChange', {
      ...this.educationOrAcademicPosition,
      from,
    });

    this.isDatepickerFromOpen = false;
  }

  public onDateToSelect(value: string) {
    const date = new Date(value);
    const to = firebase.firestore.Timestamp.fromDate(date);
    this.$emit('educationOrAcademicPositionChange', {
      ...this.educationOrAcademicPosition,
      to,
    });
    this.isDatepickerToOpen = false;
  }

  public parseDate(targetDate: Date) {
    const [year, month, date] = [
      targetDate.getFullYear(),
      targetDate.getMonth() + 1,
      targetDate.getDate(),
    ];
    return `${year}-${('0' + month).slice(-2)}-${('0' + date).slice(-2)}`;
  }

  public isEducation(
    educationOrAcademicPosition: Education | AcademicPosition,
  ): educationOrAcademicPosition is Education {
    return isEducation(educationOrAcademicPosition);
  }

  public switchToEducationOrAcademicPosition(
    type: 'Education' | 'AcademicPosition',
  ) {
    const toEducation = (event: Education | AcademicPosition): Education => {
      return Object.entries(event).reduce(
        (acc: { [key: string]: any }, [key, value]) => {
          if (key !== 'academicPosition') {
            acc[key] = value;
          }
          return acc;
        },
        { education: true },
      ) as Education;
    };

    const toAcademicPosition = (
      event: Education | AcademicPosition,
    ): AcademicPosition => {
      return Object.entries(event).reduce(
        (acc: { [key: string]: any }, [key, value]) => {
          if (key !== 'education') {
            acc[key] = value;
          }
          return acc;
        },
        { academicPosition: true },
      ) as AcademicPosition;
    };

    this.$emit(
      'educationOrAcademicPositionChange',
      type === 'Education'
        ? toEducation(this.educationOrAcademicPosition)
        : toAcademicPosition(this.educationOrAcademicPosition),
    );
  }
}
