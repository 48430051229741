export default {
  en: {
    home: 'Home',
    contacts: 'Contacts',
    contactsAndExternalLinks: 'Contacts and external links',
    customizeEvents: 'Customize Events',
    customizeCv: 'Customize CV',
    cv: 'CV',
    news: 'News',
    addNews: 'Add news',
    modifyNews: 'Modify News',
    publications: 'publications',
    iam: 'IAM',
    talks: 'Talks',
    grants: 'Grants',
    team: 'team',
    github: 'GitHub',
    about: 'About',
    privacy: 'Privacy',
    education: 'Education',
    academicPositions: 'Academic Positions',
    academicPosition: 'Academic Position',
    contactInformation: 'Contact Information',
    email: 'Email',
    address: 'Address',
    site: 'Site',
    siteName: 'Site name',
    profile: 'Profile',
    editYourProfile: 'Edit your profile',
    externalLinks: 'External links',
    firstName: 'First Name',
    lastName: 'Last Name',
    headline: 'Headline',
    subhead: 'Subhead',
    location: 'Location',
    photoURL: 'Photo URL',
    urlLink: 'URL Link',
    indicatesRequiredFields: 'indicates required field',
    save: 'Save',
    close: 'Close',
    profileUpdated: 'Profile updated',
    japanese: 'Japanese',
    english: 'English',
    type: 'Type',
    newEvent: 'New event',
    modifyEvent: 'Modify event',
    add: 'Add',
    publishedOn: 'Published on',
    reset: 'Reset',
    newCVItem: 'New CV item',
    modifyCVItem: 'Modify CV item',
    addNewPublicationOrTalkOrAward: 'Add new Publication, Talk or Award',
    modifyPublicationOrTalkOrAward: 'Modify Publication, Talk or Award',
    addItemToYourHistory: 'Add Item to your History',
    journalRef: 'Journal Ref.',
    talkRef: 'Talk Ref.',
    grantRef: 'Grant Ref.',
    awardRef: 'Award Ref.',
    membershipRef: 'Membership Ref.',
    optional: 'Optional',
    format: 'Format',
    additionalNotes: 'Additional Notes',
    externalLink: 'External link',
    achievedOn: 'Achieved on',
    modify: 'Modify',
    awards: 'Awards',
    memberships: 'Memberships',
    googleScholar: 'Google Scholar',
    researchmap: 'Researchmap',
    from: 'From',
    to: 'To',
    research: 'Research',
    next: 'Next',
    content: 'Content',
    pictureURLs: 'Picture URLs',
    addPost: 'Add post',
    submit: 'Submit',
    customizePdf: 'Customize Pdf',
  },
  ja: {
    home: 'ホーム',
    contacts: 'リンク・メール',
    contactsAndExternalLinks: 'コンタクトと外部リング',
    customizeEvents: 'イベントをカスタマイズする',
    customizeCv: 'CVをカスタマイズする',
    cv: '経歴・学歴',
    news: 'ニュース',
    addNews: 'ニュースの追加',
    modifyNews: 'ニュースの更新',
    publications: '論文',
    iam: '私の設定',
    talks: '研究発表',
    grants: '共同研究・競争的資金',
    team: 'チーム',
    github: 'GitHub',
    about: 'アバウト',
    privacy: 'プライバシー',
    education: '学歴',
    academicPositions: '経歴',
    academicPosition: '修学経歴',
    contactInformation: 'コンタクト情報',
    email: 'メール',
    address: '市・区',
    site: 'サイト',
    siteName: 'サイト名',
    profile: 'プロフィール',
    editYourProfile: 'プロフィールの編集',
    externalLinks: '外部リンク',
    firstName: '名',
    lastName: '姓',
    headline: 'ヘッドライン',
    subhead: 'サブヘッド',
    location: '場所',
    photoURL: '写真URL',
    urlLink: 'URLリンク',
    indicatesRequiredFields: 'は必須入力です',
    save: '保存',
    close: '閉じる',
    profileUpdated: 'プロフィールが更新されました',
    japanese: '日本語',
    english: '英語',
    type: 'タイプ',
    newEvent: '新規イベント',
    modifyEvent: 'イベントの更新',
    add: '追加',
    publishedOn: '公開日',
    reset: 'リセット',
    newCVItem: '新規項目',
    modifyCVItem: 'CV項目の更新',
    addNewPublicationOrTalkOrAward: 'パブりケーション・トーク・受賞の追加',
    modifyPublicationOrTalkOrAward: 'パブりケーション・トーク・受賞の更新',
    addItemToYourHistory: '履歴項目の追加',
    journalRef: 'ジャーナルRef.',
    talkRef: 'トークRef.',
    grantRef: 'グラントRef.',
    awardRef: '受賞Ref.',
    membershipRef: '委員歴Ref.',
    optional: '任意',
    format: 'フォーマット',
    additionalNotes: '追加情報',
    externalLink: '外部リンク',
    achievedOn: '受賞日',
    modify: '更新',
    awards: '受賞',
    memberships: '委員歴',
    googleScholar: 'グーグルスカラー',
    researchmap: 'リサーチマップ',
    from: '適用開始日',
    to: '適用終了日',
    research: '研究内容',
    next: '次へ',
    content: 'コンテンツ',
    pictureURLs: '画像URLs',
    addPost: '投稿する',
    submit: '送信',
    customizePdf: 'PDFをカスタマイズする',
  },
};
