








































































































































import { Component, Vue, Prop, Model, Mixins } from 'vue-property-decorator';
import * as firebase from 'firebase';
import { BaseVue } from '@/mixins';
import { News } from '../store/models';

@Component
export default class NewsEditorModal extends Mixins(BaseVue) {
  @Model('input')
  public isModalShown!: boolean;
  @Prop()
  public news!: News;
}
