export const firebaseConfig = {
  apiKey: 'AIzaSyBD6CIiVvSd8Zayg_K1aRK2b75f0FgonMQ',
  authDomain: 'naoki-sato.firebaseapp.com',
  databaseURL: 'https://naoki-sato.firebaseio.com',
  projectId: 'naoki-sato',
  storageBucket: 'naoki-sato.appspot.com',
  messagingSenderId: '765715487126',
  appId: '1:765715487126:web:03bef83909345072',
  measurementId: 'G-6PHNHP1E39',
};
