import { Module } from 'vuex';
import { UserState, RootState } from '@/store/models';
import getters from './getters';
import actions from './actions';
import mutations from './mutations';

const state: UserState = {
  isAuthenticated: false,
  profile: {
    id: '',
    firstName: '',
    lastName: '',
    photoURL: '',
    headline: '',
    academicPosition: '',
    email: '',
    locale: '',
    location: '',
    siteURL: '',
    googleScholarURL: '',
    researchmap: '',
    about: '',
    rimsURL: '',
    backgroundImgURLs: [],
    plasmaPhysicsLaboratoryURL: '',
    rest: []
  },
};

export const user: Module<UserState, RootState> = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
