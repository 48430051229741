import Vue from 'vue';
import App from './App.vue';
import router from './router';
import './registerServiceWorker';
import vuetify from './plugins/vuetify';
import store from './store/index';
import { firebaseConfig } from './firebase.config';
import FirebaseService from './services/firebase-service';
import { mapMutations } from 'vuex';
import firebase from 'firebase';
// @ts-ignore
import VueGtag from 'vue-gtag';

Vue.config.productionTip = false;
Vue.use(
  VueGtag,
  {
    config: { id: firebaseConfig.measurementId },
  },
  router,
);
const firebaseService = new FirebaseService(firebaseConfig);
Vue.prototype.$firebaseService = firebaseService;
firebase.analytics().logEvent('app_start');

Vue.filter('formatDate', (date: Date) => {
  if (!date) {
    return '';
  }
  const formattedDate = new Date(date);
  const month = ('0' + (formattedDate.getMonth() + 1)).slice(-2);
  const day = ('0' + formattedDate.getDate()).slice(-2);
  const year = formattedDate.getFullYear();
  return [year, month, day].join('-');
});

new Vue({
  router,
  // @ts-ignore
  vuetify,
  store,
  methods: {
    ...mapMutations('user', ['changeProfile']),
  },
  render: (h) => h(App),
  async created() {
    const profile = await firebaseService.getUser();
    this.changeProfile(profile);
  },
}).$mount('#app');
