import {
  Education,
  AcademicPosition,
  Event,
  Award,
  Grant,
  Talk,
  Publication,
  Membership,
} from '../store/models';
// @ts-ignore
import pdfMake from '../pdfmake';

pdfMake.fonts = {
  NotoSansCJKjp: {
    normal: 'NotoSansCJKjp-Regular.ttf',
    bold: 'NotoSansCJKjp-Regular.ttf',
    italics: 'NotoSansCJKjp-Regular.ttf',
    bolditalics: 'NotoSansCJKjp-Regular.ttf',
  },
};

function generateHeaderContent(headline: string) {
  const array = headline.split('\n');
  const mapped = array.map(value => ({
    text: value,
    style: /@/.test(value) ? ['headline', 'link'] : ['headline'],
  }));
  return [
    ...mapped,
    {
      text: '',
      margin: [0, 0, 0, 20],
    },
  ];
}

function generateEducationContent(
  this: any,
  academicPositions: AcademicPosition[],
  educationHistory: Education[]
) {
  return [academicPositions, educationHistory].flatMap((arr, index) => {
    const content1 = {
      text: index === 0 ? 'Academic Position' : 'Education',
      style: ['header'],
    };
    const content2 = ((arr as unknown) as any).map(
      (position: AcademicPosition | Education) => {
        const content1 = {
          text: position.headline,
          style: ['headline'],
        };
        const content2 = {
          text: position.subhead,
          style: ['subhead', 'link'],
        };
        const content3 = {
          text: `${this.$parseTimestamp(
            position.from
          )} — ${this.$parseTimestamp(position.to)}`,
          style: ['subhead', 'last'],
        };
        return [content1, content2, content3];
      }
    );
    return [content1, ...content2, { text: '', margin: [0, 0, 0, 10] }];
  });
}

function generateEventContent(
  publications: Publication[],
  talks: Talk[],
  grants: Grant[],
  awards: Award[],
  memberships: Membership[]
) {
  return [
    publications as Event[],
    talks as Event[],
    grants as Event[],
    awards as Event[],
    memberships as Event[],
  ].flatMap((events, index) => {
    const eventsByYear = events.reduce(
      (acc: { [year: string]: Event[] }, event: Event) => {
        const year = event.on ? event.on.toDate().getFullYear() : '9999';
        acc[year] = acc[year] ? [...acc[year], event] : [event];
        return acc;
      },
      {} as any
    );
    const header = {
      text:
        index === 0
          ? 'Publications'
          : index === 1
          ? 'Talks'
          : index === 2
          ? 'Grants'
          : index === 3
          ? 'Awards'
          : 'Memberships',
      style: ['header'],
    };
    const contents = Object.entries(eventsByYear)
      .sort(([yearA], [yearB]) => +yearB - +yearA)
      .map(([year, value]) => [
        { text: year, fontSize: 9 },
        {
          canvas: [
            {
              lineColor: '#444444',
              type: 'line',
              x1: 0,
              y1: 0,
              x2: 512,
              y2: 0,
              lineWidth: 1,
            },
          ],
        },
        { text: '', fontSize: 9, margin: [0, 0, 0, 10] },
        ...value.map(event => [
          {
            text: event.headline || event.headlineJa,
            style: ['headline'],
          },
          {
            text: event.subhead || event.subheadJa,
            style: ['subhead', 'last'],
          },
          ...event.externalLinks.map(link => ({
            text: link,
            style: ['subhead', 'link', 'last'],
          })),
        ]),
      ]);
    return [header, ...contents, { text: '', margin: [0, 0, 0, 10] }];
  });
}

function generateContent(content: any[]) {
  // JSON形式で content にPDF出力用のデータを設定
  return {
    pageSize: 'A4',
    header(currentPage: any, pageCount: any, pageSize: any) {
      return [
        {
          canvas: [
            {
              type: 'rect',
              x: 0,
              y: 0,
              w: pageSize.width,
              h: 1,
              background: '#444444',
            },
          ],
        },
      ];
    },
    content,
    styles: {
      header: {
        lineHeight: 1.5,
        fontSize: 12,
      },
      headline: {
        fontSize: 10,
      },
      subhead: {
        fontSize: 9,
      },
      link: {
        bold: true,
        color: '#2196F3',
      },
      last: {
        margin: [0, 0, 0, 5],
      },
    },
    defaultStyle: {
      font: 'NotoSansCJKjp',
      color: '#444444',
    },
  };
}

export default {
  generateHeaderContent,
  generateEducationContent,
  generateEventContent,
  generateContent,
};
