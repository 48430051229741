var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"value":_vm.isModalShown,"persistent":"","transition":"","max-width":"800","scrollable":""},on:{"input":function($event){return _vm.$emit('input', false)}}},[_c('v-card',{staticClass:"mx-auto",attrs:{"color":"white","max-width":"1100"}},[_c('v-card-title',{staticClass:"blue-grey darken-2"},[_c('span',{staticClass:"title blue-grey--text text--lighten-5"},[_vm._v(_vm._s(_vm.$translations.modify))])]),_c('v-card-text',[_c('small',[_vm._v(_vm._s(_vm.$translations.addItemToYourHistory))]),_c('v-container',{attrs:{"grid-list-md":""}},[_c('v-layout',[_c('v-flex',[_c('v-card',{attrs:{"flat":""}},[_c('v-layout',{attrs:{"wrap":""}},[_c('v-flex',{attrs:{"xs12":""}},[_c('v-textarea',{attrs:{"value":_vm.research.headline,"label":_vm.$translations.headline,"auto-grow":"","rows":"1"},on:{"input":function($event){return _vm.$emit('researchChange', Object.assign({}, _vm.research,
                        {headline: $event}))}}})],1),_c('v-flex',{attrs:{"xs12":""}},[_c('v-textarea',{attrs:{"value":_vm.research.headlineJa,"label":((_vm.$translations.headline) + " (" + (_vm.$translations.optional) + ")"),"auto-grow":"","rows":"1"},on:{"input":function($event){return _vm.$emit('researchChange', Object.assign({}, _vm.research,
                        {headlineJa: $event}))}}})],1),_c('v-flex',{attrs:{"xs12":""}},[_c('v-textarea',{attrs:{"value":_vm.research.subhead,"label":_vm.$translations.subhead,"auto-grow":"","rows":"1"},on:{"input":function($event){return _vm.$emit('researchChange', Object.assign({}, _vm.research,
                        {subhead: $event}))}}})],1),_c('v-flex',{attrs:{"xs12":""}},[_c('v-textarea',{attrs:{"value":_vm.research.subheadJa,"label":((_vm.$translations.subhead) + " (" + (_vm.$translations.optional) + ")"),"auto-grow":"","rows":"1"},on:{"input":function($event){return _vm.$emit('researchChange', Object.assign({}, _vm.research,
                        {subheadJa: $event}))}}})],1),_c('v-flex',{attrs:{"xs12":""}},[_c('v-textarea',{attrs:{"value":_vm.research.content,"label":_vm.$translations.content,"auto-grow":"","rows":"1"},on:{"input":function($event){return _vm.$emit('researchChange', Object.assign({}, _vm.research,
                        {content: $event}))}}})],1),_c('v-flex',{attrs:{"xs12":""}},[_c('v-textarea',{attrs:{"value":_vm.research.contentJa,"label":((_vm.$translations.content) + " (" + (_vm.$translations.optional) + ")"),"auto-grow":"","rows":"1"},on:{"input":function($event){return _vm.$emit('researchChange', Object.assign({}, _vm.research,
                        {contentJa: $event}))}}})],1),_c('v-flex',{attrs:{"xs12":""}},[_c('v-textarea',{attrs:{"value":_vm.research.externalLinks.join(' '),"label":((_vm.$translations.externalLinks) + " (" + (_vm.$translations.optional) + ")"),"auto-grow":"","rows":"1"},on:{"input":function($event){_vm.$emit('researchChange', Object.assign({}, _vm.research,
                        {externalLinks: $event
                          .split(/\b\s+/)
                          .filter(function (value) { return !!value.trim(); })}))}}})],1),_c('v-flex',{attrs:{"xs12":""}},[_c('v-textarea',{attrs:{"value":_vm.research.pictureURLs.join(' '),"label":((_vm.$translations.content) + " (" + (_vm.$translations.optional) + ")"),"auto-grow":"","rows":"1"},on:{"input":function($event){_vm.$emit('researchChange', Object.assign({}, _vm.research,
                        {pictureURLs: $event
                          .split(/\b\s+/)
                          .filter(function (pic) { return !!pic.trim(); })}))}}})],1)],1)],1)],1)],1)],1),_c('small',[_vm._v("*"+_vm._s(_vm.$translations.indicatesRequiredFields))])],1),_c('v-card-actions',{staticClass:"blue-grey darken-2"},[_c('v-spacer'),_c('v-btn',{staticClass:"white--text",attrs:{"text":""},on:{"click":function($event){return _vm.$emit('close')}}},[_vm._v(_vm._s(_vm.$translations.close))]),_c('v-btn',{staticClass:"white--text",attrs:{"text":""},on:{"click":function($event){return _vm.$emit('modify', _vm.research)}}},[_vm._v(_vm._s(_vm.$translations.modify))])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }