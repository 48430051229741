import * as firebase from 'firebase';

export interface RootState {
  version: string;
}

export interface UserState {
  isAuthenticated: boolean;
  profile: Profile;
}

interface Base {
  createdAt: firebase.firestore.Timestamp;
  updatedAt: firebase.firestore.Timestamp;
}

/**
 * Profile
 */

export interface Profile extends Details, Partial<Base> {
  id: string;
  firstName: string;
  lastName: string;
  photoURL: string;
  email: string;
  locale: string;
}

interface Details {
  headline: string;
  about: string;
  academicPosition: string;
  location: string;
  siteURL: string;
  googleScholarURL: string;
  researchmap: string;
  rimsURL: string;
  plasmaPhysicsLaboratoryURL: string;
  backgroundImgURLs: string[];
  rest: Array<{ name: string; url: string }>;
}

/**
 * Education History - Academic Position
 */

export interface PersonalHistoryState {
  educationHistory: Education[];
  academicPositions: AcademicPosition[];
  publications: Publication[];
  talks: Talk[];
  grants: Grant[];
  awards: Award[];
  memberships: Membership[];
  allNews: News[];
}

export interface Education extends Event, Partial<Base> {
  from?: firebase.firestore.Timestamp;
  to?: firebase.firestore.Timestamp;
  education: true;
}

export interface AcademicPosition extends Event, Partial<Base> {
  from?: firebase.firestore.Timestamp;
  to?: firebase.firestore.Timestamp;
  academicPosition: true;
}

export function isEducation(
  arg: Education | AcademicPosition
): arg is Education {
  return 'education' in arg;
}

export function isAcademicPosition(
  arg: Education | AcademicPosition
): arg is AcademicPosition {
  return 'academicPosition' in arg;
}

export interface Publication extends Event, Partial<Base> {
  arXiv: string;
}

export interface Talk extends Event, Partial<Base> {
  talk: true;
}

export interface Grant extends Event, Partial<Base> {
  grant: true;
}

export interface Award extends Event, Partial<Base> {
  award: true;
}

export interface Membership extends Event, Partial<Base> {
  membership: true;
}

export type Categories = Publication | Talk | Grant | Award | Membership;

export function isPublication(arg: Categories): arg is Publication {
  return 'arXiv' in arg;
}

export function isTalk(arg: Categories): arg is Talk {
  return 'talk' in arg;
}

export function isGrant(arg: Categories): arg is Grant {
  return 'grant' in arg;
}

export function isAward(arg: Categories): arg is Award {
  return 'award' in arg;
}

export function isMembership(arg: Categories): arg is Membership {
  return 'membership' in arg;
}

export interface Research extends Event {
  content: string;
  contentJa?: string;
  pictureURLs: string[];
}

export interface News
  extends Partial<Publication>,
    Partial<Talk>,
    Partial<Grant>,
    Partial<Award>,
    Partial<Membership> {
  status: 'New' | 'Update' | 'Hot' | 'None';
  displayOrder: number;
}

export interface Event {
  id?: string;
  headline: string;
  headlineJa?: string;
  subhead: string;
  subheadJa?: string;
  on?: firebase.firestore.Timestamp;
  externalLinks: string[];
}
