













































































































































































import { Component, Vue, Mixins } from 'vue-property-decorator';
import { BaseVue } from '../mixins';
import { Mutation, Getter } from 'vuex-class';
import { Education, AcademicPosition, Profile } from '../store/models';
import CurriculumVitaeEditorModal from '@/components/CurriculumVitaeEditorModal.vue';
import firebase from 'firebase';

@Component({ components: { CurriculumVitaeEditorModal } })
export default class CurriculumVitae extends Mixins(BaseVue) {
  public isCurriculumVitaeModalOpen = false;
  public educationOrAcademicPosition:
    | Education
    | AcademicPosition = this.getNewAcademicPosition();

  @Getter('profile', { namespace: 'user' })
  public profile!: Profile;
  @Getter('isAuthenticated', { namespace: 'user' })
  public isAuthenticated!: boolean;
  @Getter('educationHistory', { namespace: 'personalHistory' })
  public educationHistory!: Education[];
  @Getter('academicPositions', { namespace: 'personalHistory' })
  public academicPositions!: AcademicPosition[];

  @Mutation('addEducation', { namespace: 'personalHistory' })
  private addEducation!: (education: Education) => void;
  @Mutation('removeAllEducationHistory', { namespace: 'personalHistory' })
  private removeAllEducationHistory!: () => void;

  @Mutation('addAcademicPosition', { namespace: 'personalHistory' })
  private addAcademicPosition!: (addAcademicPosition: AcademicPosition) => void;
  @Mutation('removeAllAcademicPositions', { namespace: 'personalHistory' })
  private removeAllAcademicPositions!: () => void;

  public getNewAcademicPosition(): AcademicPosition {
    return {
      externalLinks: [],
      headline: '',
      headlineJa: '',
      subhead: '',
      subheadJa: '',
      academicPosition: true,
      on: firebase.firestore.Timestamp.now(),
      from: firebase.firestore.Timestamp.now(),
      to: firebase.firestore.Timestamp.now(),
    };
  }
  public unsubscribeFromEducationHistory: () => void = () => {};
  public unsubscribeFromAcademicPositions: () => void = () => {};

  public transform(event: Education | AcademicPosition) {
    // TODO Not Sure why it works in real-time (Maybe it would be better to change the approach)
    // --Same as Event.vue
    const en = {
      headline: event.headline || event.headlineJa,
      subhead: event.subhead || event.subheadJa,
    };
    const ja = {
      headline: event.headlineJa || event.headline,
      subhead: event.subheadJa || event.subhead,
    };
    return { en, ja }[this.$language];
  }

  public onEditClick(
    educationOrAcademicPosition: Education | AcademicPosition
  ) {
    this.isCurriculumVitaeModalOpen = true;
    this.educationOrAcademicPosition = educationOrAcademicPosition;
  }

  public onEducationOrAcademicPositionChange(
    educationOrAcademicPosition: AcademicPosition | Education
  ) {
    this.educationOrAcademicPosition = educationOrAcademicPosition;
  }

  public async onModifyClick() {
    this.isCurriculumVitaeModalOpen = false;
    const educationOrAcademicPosition = this.educationOrAcademicPosition;
    this.educationOrAcademicPosition = this.getNewAcademicPosition();
    const result = await this.$firebaseService.modifyEducationOrAcademicPosition(
      educationOrAcademicPosition
    );
  }

  public async onRemoveClick(academicPosition: AcademicPosition | Education) {
    return this.$firebaseService.removeEducationOrAcademicPosition(
      academicPosition
    );
  }

  public onCloseClick() {
    this.isCurriculumVitaeModalOpen = false;
  }

  public async created() {
    this.unsubscribeFromEducationHistory = this.$firebaseService.obtainEducationHistory(
      educationHistory => {
        this.removeAllEducationHistory();
        educationHistory.forEach((education, index) =>
          this.addEducation(education)
        );
      }
    );

    this.unsubscribeFromAcademicPositions = this.$firebaseService.obtainAcademicPositions(
      academicPositions => {
        this.removeAllAcademicPositions();
        academicPositions.forEach((academicPosition, index) =>
          this.addAcademicPosition(academicPosition)
        );
      }
    );
  }

  public async destroyed() {
    this.unsubscribeFromEducationHistory();
    this.unsubscribeFromAcademicPositions();
  }
}
