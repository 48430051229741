import { MutationTree } from 'vuex';
import { UserState, Profile } from '@/store/models';

const mutations: MutationTree<UserState> = {
  changeProfile: (state, profile: Profile) => {
    state.profile = profile;
  },
  changeAuthStatus: (state, isAuthenticated: boolean) => {
    state.isAuthenticated = isAuthenticated;
  },
};

export default mutations;
