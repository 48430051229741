





























































































































































































































import { Component, Vue, Mixins } from 'vue-property-decorator';
import { BaseVue } from '../mixins';
import { Getter } from 'vuex-class';
import { Profile } from '@/store/models';

@Component
export default class Contact extends Mixins(BaseVue) {
  @Getter('profile', { namespace: 'user' })
  public profile!: Profile;

  public get rndPic() {
    const randomVal = Math.floor(
      this.profile.backgroundImgURLs.length * Math.random()
    );
    return this.profile.backgroundImgURLs[randomVal];
  }
}
