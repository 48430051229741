
























































































































































































































































































































































































































































import { Component, Vue, Mixins } from 'vue-property-decorator';
import { BaseVue } from '../mixins';
import Event from './../components/Event.vue';
import EventEditorModal from '@/components/EventEditorModal.vue';
import NewsEditorModal from '@/components/NewsEditorModal.vue';
import { Getter, Mutation } from 'vuex-class';
import {
  Profile,
  Publication,
  Talk,
  Education,
  AcademicPosition,
  isPublication,
  Award,
  News,
  Event as EventModel,
  Grant,
  isTalk,
  isGrant,
  isAward,
  Membership,
  Categories,
  isMembership,
} from '@/store/models';
import * as firebase from 'firebase';

@Component({ components: { Event, EventEditorModal, NewsEditorModal } })
export default class Home extends Mixins(BaseVue) {
  public get fullName() {
    return (this.profile.firstName + ' ' + this.profile.lastName).trim();
  }

  @Getter('isAuthenticated', { namespace: 'user' })
  public isAuthenticated!: boolean;

  @Getter('profile', { namespace: 'user' })
  public profile!: Profile;
  @Getter('publications', { namespace: 'personalHistory' })
  public publications!: Publication[];
  @Getter('talks', { namespace: 'personalHistory' })
  public talks!: Talk[];
  @Getter('grants', { namespace: 'personalHistory' })
  public grants!: Grant[];
  @Getter('awards', { namespace: 'personalHistory' })
  public awards!: Award[];
  @Getter('memberships', { namespace: 'personalHistory' })
  public memberships!: Membership[];
  @Getter('allNews', { namespace: 'personalHistory' })
  public allNews!: News[];
  public isEventEditorModalOpen = false;
  public isNewsEditorModalOpen = false;
  public event: Categories = this.getNewEvent();
  public news: News = this.getEmptyNews();
  public tab = null;
  public isLoading = false;

  @Mutation('addPublication', { namespace: 'personalHistory' })
  private addPublication!: (publication: Publication) => void;
  @Mutation('removeAllPublications', { namespace: 'personalHistory' })
  private removeAllPublications!: () => void;
  @Mutation('addTalk', { namespace: 'personalHistory' })
  private addTalk!: (talk: Talk) => void;
  @Mutation('removeAllTalks', { namespace: 'personalHistory' })
  private removeAllTalks!: () => void;
  @Mutation('addGrant', { namespace: 'personalHistory' })
  private addGrant!: (grant: Grant) => void;
  @Mutation('removeAllGrants', { namespace: 'personalHistory' })
  private removeAllGrants!: () => void;
  @Mutation('addAward', { namespace: 'personalHistory' })
  private addAward!: (award: Award) => void;
  @Mutation('removeAllAwards', { namespace: 'personalHistory' })
  private removeAllAwards!: () => void;
  @Mutation('addMembership', { namespace: 'personalHistory' })
  private addMembership!: (award: Membership) => void;
  @Mutation('removeAllMemberships', { namespace: 'personalHistory' })
  private removeAllMemberships!: () => void;
  @Mutation('addNews', { namespace: 'personalHistory' })
  private addNews!: (award: News) => void;
  @Mutation('removeAllNews', { namespace: 'personalHistory' })
  private removeAllNews!: () => void;

  public transform(event: Publication | Talk | Grant | Award) {
    // TODO Not Sure why it works in real-time (Maybe it would be better to change the approach)
    // --Same as Event.vue
    const en = {
      headline: event.headline || event.headlineJa,
      subhead: event.subhead || event.subheadJa,
    };
    const ja = {
      headline: event.headlineJa || event.headline,
      subhead: event.subheadJa || event.subhead,
    };
    return { en, ja }[this.$language];
  }

  public toEventNm(event: Categories) {
    return isPublication(event)
      ? 'Publication'
      : isTalk(event)
      ? 'Talk'
      : isGrant(event)
      ? 'Grant'
      : isAward(event)
      ? 'Award'
      : isMembership(event)
      ? 'Membership'
      : 'Custom Event';
  }

  public unsubscribeFromPublications: () => void = () => {};
  public unsubscribeFromTalks: () => void = () => {};
  public unsubscribeFromAwards: () => void = () => {};
  public unsubscribeFromGrants: () => void = () => {};
  public unsubscribeFromMemberships: () => void = () => {};
  public unsubscribeFromAllNews: () => void = () => {};

  public getNewEvent(): Publication {
    return {
      externalLinks: [],
      headline: '',
      headlineJa: '',
      subhead: '',
      subheadJa: '',
      arXiv: '',
      on: firebase.firestore.Timestamp.now(),
    };
  }

  public getEmptyNews(): News {
    return {
      status: 'New',
      externalLinks: [''],
      displayOrder: 1,
      headline: '',
      headlineJa: '',
      subhead: '',
      subheadJa: '',
      on: firebase.firestore.Timestamp.now(),
    };
  }

  public parseTimestamp(timestamp: firebase.firestore.Timestamp) {
    const parseDate = (targetDate: Date) => {
      const [year, month, date, hours, minutes] = [
        targetDate.getFullYear(),
        targetDate.getMonth() + 1,
        targetDate.getDate(),
        targetDate.getHours(),
        targetDate.getMinutes(),
      ];
      return `${year}-${('0' + month).slice(-2)}-${('0' + date).slice(-2)} ${(
        '0' + hours
      ).slice(-2)}:${('0' + minutes).slice(-2)}`;
    };
    return timestamp ? parseDate(timestamp.toDate()) : '9999-12-31';
  }

  public onModifyEvent(event: Categories) {
    this.isEventEditorModalOpen = true;
    this.event = event;
  }

  public async onRemoveEvent(event: Categories) {
    const response = await this.$firebaseService.removeEvent(event);
  }

  public onEventChange(event: Categories) {
    this.event = event;
  }

  public async onModifyEventClick() {
    this.isEventEditorModalOpen = false;

    const event = {
      ...this.event,
      // Normalize the External Links
      externalLinks: this.event.externalLinks.filter(
        (link, index) => index === 0 || link !== ''
      ),
    };
    this.event = this.getNewEvent();
    await this.$firebaseService.modifyEvent(event);

    this.onModifyNewsClick({
      ...event,
      status: 'Update',
      displayOrder:
        this.allNews.length > 0 ? this.allNews[0].displayOrder + 1 : 1,
    });
  }

  public async onCloseEventClick() {
    this.isEventEditorModalOpen = false;
    this.event = this.getNewEvent();
  }

  // News -----------------------------------------
  public onModifyNews(news: News) {
    this.isNewsEditorModalOpen = true;
    this.news = news;
  }

  public async onChangeDisplayOrder(news: News, prevOrNextNews?: News) {
    if (!prevOrNextNews) {
      return;
    }
    await Promise.all([
      this.onModifyNewsClick({
        ...news,
        displayOrder: prevOrNextNews.displayOrder,
      }),
      this.onModifyNewsClick({
        ...prevOrNextNews,
        displayOrder: news.displayOrder,
      }),
    ]);
  }

  public async onNewsChange(news: News) {
    this.news = news;
  }

  public async onModifyNewsClick(news: News) {
    this.isNewsEditorModalOpen = false;
    this.news = this.getEmptyNews();
    news.id
      ? await this.$firebaseService.modifyNews(news)
      : await this.$firebaseService.addNews({
          ...news,
          displayOrder:
            this.allNews.length > 0 ? this.allNews[0].displayOrder + 1 : 1,
        });
  }

  public onRemoveNewsClick(news: News) {
    this.$firebaseService.removeNews(news);
  }

  public onCloseNewsClick() {
    this.isNewsEditorModalOpen = false;
    this.news = this.getEmptyNews();
  }

  public async created() {
    this.unsubscribeFromPublications = this.$firebaseService.obtainPublications(
      publications => {
        this.removeAllPublications();
        publications.forEach((publication, index) =>
          this.addPublication(publication)
        );
      }
    );

    this.unsubscribeFromTalks = this.$firebaseService.obtainTalks(talks => {
      this.removeAllTalks();
      talks.forEach((talk, index) => this.addTalk(talk));
    });

    this.unsubscribeFromAwards = this.$firebaseService.obtainAwards(awards => {
      this.removeAllAwards();
      awards.forEach((award, index) => this.addAward(award));
    });

    this.unsubscribeFromGrants = this.$firebaseService.obtainGrants(grants => {
      this.removeAllGrants();
      grants.forEach((grant, index) => this.addGrant(grant));
    });

    this.unsubscribeFromMemberships = this.$firebaseService.obtainMemberships(
      memberships => {
        this.removeAllMemberships();
        memberships.forEach((membership, index) =>
          this.addMembership(membership)
        );
      }
    );

    this.unsubscribeFromAllNews = this.$firebaseService.obtainNews(allNews => {
      this.removeAllNews();
      allNews.forEach((news, index) =>
        this.addNews({
          ...news,
          externalLinks:
            news.externalLinks && news.externalLinks[0]
              ? news.externalLinks
              : [''],
        })
      );
    });
  }

  public async download() {
    this.isLoading = true;
    await this.$router.push('pdf');
    this.isLoading = false;
  }

  public destroyed() {
    this.unsubscribeFromPublications();
    this.unsubscribeFromTalks();
    this.unsubscribeFromAwards();
    this.unsubscribeFromGrants();
    this.unsubscribeFromMemberships();
    this.unsubscribeFromAllNews();
  }
}
