
























































































































































import { Component, Vue, Mixins } from 'vue-property-decorator';
import { BaseVue } from '../mixins';
import { Getter, Mutation } from 'vuex-class';
import { Profile, Publication, Talk } from '@/store/models';

@Component
export default class Privacy extends Mixins(BaseVue) {
  @Getter('profile', { namespace: 'user' })
  public profile!: Profile;

  public get siteURL() {
    return `${this.profile.firstName.toLowerCase()}${this.profile.lastName.toLowerCase()}.work`;
  }
}
