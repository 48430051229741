import { MutationTree } from 'vuex';
import {
  PersonalHistoryState,
  Education,
  AcademicPosition,
  Publication,
  Talk,
  Award,
  News,
  Grant,
  Membership,
} from '@/store/models';

const mutations: MutationTree<PersonalHistoryState> = {
  addEducation: (state, educationHistory: Education) => {
    state.educationHistory.push(educationHistory);
  },
  addAcademicPosition: (state, academicPositions: AcademicPosition) => {
    state.academicPositions.push(academicPositions);
  },

  addPublication: (state, publication: Publication) => {
    state.publications.push(publication);
  },

  addTalk: (state, talk: Talk) => {
    state.talks.push(talk);
  },

  addGrant: (state, grant: Grant) => {
    state.grants.push(grant);
  },

  addAward: (state, award: Award) => {
    state.awards.push(award);
  },

  addMembership: (state, membership: Membership) => {
    state.memberships.push(membership);
  },

  addNews: (state, news: News) => {
    state.allNews.push(news);
  },

  removeAllEducationHistory: state => {
    state.educationHistory = [];
  },
  removeAllAcademicPositions: state => {
    state.academicPositions = [];
  },

  removeAllPublications: state => {
    state.publications = [];
  },

  removeAllTalks: state => {
    state.talks = [];
  },

  removeAllGrants: state => {
    state.grants = [];
  },

  removeAllAwards: state => {
    state.awards = [];
  },

  removeAllMemberships: state => {
    state.memberships = [];
  },

  removeAllNews: state => {
    state.allNews = [];
  },
};

export default mutations;
