




























































import { Component, Vue, Mixins } from 'vue-property-decorator';
import { BaseVue } from '../mixins';
import firebaseService from '../services/firebase-service';
import { Getter } from 'vuex-class';
import { Profile } from '../store/models';

@Component
export default class Navigation extends Mixins(BaseVue) {
  @Getter('profile', { namespace: 'user' })
  public profile!: Profile;

  @Getter('isAuthenticated', { namespace: 'user' })
  public isAuthenticated!: boolean;

  public get languagesList() {
    return ['English', 'Japanese'];
  }

  public get currentLanguage() {
    return this.$language === 'ja' ? 'Japanese' : 'English';
  }

  public onLanguageChange(language: 'English' | 'Japanese') {
    this.$router.replace({
      ...this.$route,
      query: { lang: language === 'Japanese' ? 'ja' : 'en' },
    });
  }

  public async signInWithGoogle() {
    const result = await this.$firebaseService.singInWithGoogle();
    // Disallow new user
    // if (result && result.isNewUser) {
    //   await this.$firebaseService.registerUser(result.user);
    // }
  }

  public async signOut() {
    this.$firebaseService.signOut();
  }
}
