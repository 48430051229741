var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-layout',{attrs:{"column":"","pa-2":""}},[_c('v-card',{staticClass:"mx-auto",attrs:{"color":"white","max-width":"1100"}},[_c('v-card-title',{staticClass:"blue-grey darken-2"},[_c('span',{staticClass:"title blue-grey--text text--lighten-5"},[_vm._v(_vm._s(_vm.$translations.newEvent))])]),_c('v-card-text',[_c('small',[_vm._v(_vm._s(_vm.$translations.addNewPublicationOrTalkOrAward))]),_c('v-container',{attrs:{"grid-list-md":""}},[_c('v-layout',[_c('v-flex',[_c('v-card',{attrs:{"flat":""}},[_c('v-layout',{attrs:{"wrap":""}},[_c('v-flex',{attrs:{"sm5":""}},[_c('v-select',{attrs:{"value":_vm.isPublication(_vm.event)
                          ? 'Publication'
                          : _vm.isTalk(_vm.event)
                          ? 'Talk'
                          : _vm.isGrant(_vm.event)
                          ? 'Grant'
                          : _vm.isAward(_vm.event)
                          ? 'Award'
                          : 'Membership',"items":[
                        'Publication',
                        'Talk',
                        'Grant',
                        'Award',
                        'Membership' ],"label":_vm.$translations.type},on:{"input":function($event){return _vm.switchEvent($event)}}})],1),_c('v-flex',{attrs:{"offset-sm3":"","sm4":""}},[_c('v-menu',{attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","full-width":"","max-width":"290px","min-width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                      var on = ref.on;
return [_c('v-text-field',_vm._g({attrs:{"label":_vm.$translations.publishedOn,"hint":("YYYY-MM-DD " + (_vm.$translations.format)),"persistent-hint":"","prepend-icon":"event","readonly":""},model:{value:(_vm.date),callback:function ($$v) {_vm.date=$$v},expression:"date"}},on))]}}]),model:{value:(_vm.isDatepickerOpen),callback:function ($$v) {_vm.isDatepickerOpen=$$v},expression:"isDatepickerOpen"}},[_c('v-date-picker',{attrs:{"no-title":"","value":_vm.date,"color":"blue"},on:{"input":_vm.onDateSelect}})],1)],1),_c('v-flex',{attrs:{"xs12":""}},[_c('v-textarea',{attrs:{"name":"input-7-1","label":_vm.isPublication(_vm.event)
                          ? _vm.$translations.journalRef
                          : _vm.isTalk(_vm.event)
                          ? _vm.$translations.talkRef
                          : _vm.isGrant(_vm.event)
                          ? _vm.$translations.grantRef
                          : _vm.isAward(_vm.event)
                          ? _vm.$translations.awardRef
                          : _vm.$translations.membershipRef,"auto-grow":"","rows":"1"},model:{value:(_vm.event.headline),callback:function ($$v) {_vm.$set(_vm.event, "headline", $$v)},expression:"event.headline"}})],1),_c('v-flex',{attrs:{"xs12":""}},[_c('v-textarea',{attrs:{"name":"input-7-1","label":_vm.isPublication(_vm.event)
                          ? ((_vm.$translations.journalRef) + " (" + (_vm.$translations.optional) + ")")
                          : _vm.isTalk(_vm.event)
                          ? ((_vm.$translations.talkRef) + " (" + (_vm.$translations.optional) + ")")
                          : _vm.isGrant(_vm.event)
                          ? _vm.$translations.grantRef
                          : _vm.isAward(_vm.event)
                          ? ((_vm.$translations.awardRef) + " (" + (_vm.$translations.optional) + ")")
                          : ((_vm.$translations.membershipRef) + " (" + (_vm.$translations.optional) + ")"),"auto-grow":"","rows":"1"},model:{value:(_vm.event.headlineJa),callback:function ($$v) {_vm.$set(_vm.event, "headlineJa", $$v)},expression:"event.headlineJa"}})],1),_c('v-flex',{attrs:{"xs12":""}},[_c('v-textarea',{attrs:{"name":"input-7-1","label":_vm.$translations.additionalNotes,"auto-grow":"","rows":"1"},model:{value:(_vm.event.subhead),callback:function ($$v) {_vm.$set(_vm.event, "subhead", $$v)},expression:"event.subhead"}})],1),_c('v-flex',{attrs:{"xs12":""}},[_c('v-textarea',{attrs:{"name":"input-7-1","label":((_vm.$translations.additionalNotes) + " (" + (_vm.$translations.optional) + ")"),"auto-grow":"","rows":"1"},model:{value:(_vm.event.subheadJa),callback:function ($$v) {_vm.$set(_vm.event, "subheadJa", $$v)},expression:"event.subheadJa"}})],1),_vm._l((_vm.event.externalLinks),function(externalLink,index){return _c('v-flex',{key:("external-link-" + index),attrs:{"xs12":""}},[_c('v-row',{attrs:{"align":"baseline"}},[_c('v-col',{attrs:{"cols":"11"}},[_c('v-text-field',{attrs:{"value":externalLink,"name":"input-7-1","label":_vm.$translations.externalLink,"auto-grow":"","rows":"1","persistent-hint":"","hint":"URL link"},on:{"input":function ($event) {
                              _vm.event.externalLinks = (function () {
                                var left = _vm.event.externalLinks.slice(
                                  0,
                                  index
                                );
                                var right = _vm.event.externalLinks.slice(
                                  index + 1,
                                  _vm.event.externalLinks.length
                                );
                                return left.concat( [$event], right);
                              })();
                            }}})],1),(index == 0)?_c('v-col',{attrs:{"cols":"1"}},[_c('v-btn',{attrs:{"elevtion":"0","rounded":"","small":"","fab":""}},[_c('v-icon',{attrs:{"dark":""},on:{"click":function () {
                                _vm.event.externalLinks = _vm.event.externalLinks.concat( [''] );
                              }}},[_vm._v(" add ")])],1)],1):_vm._e()],1)],1)}),_c('v-flex',{attrs:{"xs12":""}},[(_vm.isPublication(_vm.event))?_c('v-text-field',{attrs:{"name":"input-7-1","label":"arXiv","auto-grow":"","rows":"1","persistent-hint":"","hint":_vm.$translations.urlLink},model:{value:(_vm.event.arXiv),callback:function ($$v) {_vm.$set(_vm.event, "arXiv", $$v)},expression:"event.arXiv"}}):_vm._e()],1)],2)],1)],1)],1)],1),_c('small',[_vm._v("*"+_vm._s(_vm.$translations.indicatesRequiredFields))])],1),_c('v-card-actions',{staticClass:"blue-grey darken-2"},[_c('v-spacer'),_c('v-btn',{staticClass:"white--text",attrs:{"text":""},on:{"click":_vm.onResetClick}},[_vm._v(_vm._s(_vm.$translations.reset))]),_c('v-btn',{staticClass:"white--text",attrs:{"text":""},on:{"click":_vm.onAddClick}},[_vm._v(_vm._s(_vm.$translations.add))])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }