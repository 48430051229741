














































































































































































































import { Component, Vue, Prop, Model, Mixins } from 'vue-property-decorator';
import * as firebase from 'firebase';
import { BaseVue } from '@/mixins';
import {
  Publication,
  Talk,
  isPublication,
  Award,
  isTalk,
  Grant,
  isGrant,
  Membership,
  Categories,
  isAward,
} from '../store/models';

@Component
export default class EventEditorModal extends Mixins(BaseVue) {
  @Model('input')
  public isModalShown!: boolean;
  @Prop()
  public event!: Publication | Talk | Grant | Award;
  public isDatepickerOpen: boolean = false;

  public get date() {
    return this.event.on
      ? this.parseDate(this.event.on.toDate())
      : this.parseDate(new Date());
  }

  public onDateSelect(value: string) {
    const date = new Date(value);
    const on = firebase.firestore.Timestamp.fromDate(date);
    this.$emit('eventChange', { ...this.event, on });
    this.isDatepickerOpen = false;
  }

  public parseDate(targetDate: Date) {
    const [year, month, date] = [
      targetDate.getFullYear(),
      targetDate.getMonth() + 1,
      targetDate.getDate(),
    ];
    return `${year}-${('0' + month).slice(-2)}-${('0' + date).slice(-2)}`;
  }

  public isPublication(event: Categories): event is Publication {
    return isPublication(event);
  }
  public isTalk(event: Categories): event is Talk {
    return isTalk(event);
  }

  public isGrant(event: Categories): event is Grant {
    return isGrant(event);
  }

  public isAward(event: Categories): event is Grant {
    return isAward(event);
  }

  public switchEvent(
    type: 'Publication' | 'Talk' | 'Grant' | 'Award' | 'Membership'
  ) {
    const toPublication = (event: Categories): Publication => {
      return Object.entries(event).reduce(
        (acc: { [key: string]: any }, [key, value]) => {
          if (
            key !== 'talk' &&
            key !== 'grant' &&
            key !== 'award' &&
            key !== 'membership'
          ) {
            acc[key] = value;
          }
          return acc;
        },
        { arXiv: '' }
      ) as Publication;
    };
    const toTalk = (event: Categories): Talk => {
      return Object.entries(event).reduce(
        (acc: { [key: string]: any }, [key, value]) => {
          if (
            key !== 'arXiv' &&
            key !== 'grant' &&
            key !== 'award' &&
            key !== 'membership'
          ) {
            acc[key] = value;
          }
          return acc;
        },
        { talk: true }
      ) as Talk;
    };
    const toGrant = (event: Categories): Award => {
      return Object.entries(event).reduce(
        (acc: { [key: string]: any }, [key, value]) => {
          if (
            key !== 'arXiv' &&
            key !== 'talk' &&
            key !== 'award' &&
            key !== 'membership'
          ) {
            acc[key] = value;
          }
          return acc;
        },
        { grant: true }
      ) as Award;
    };
    const toAward = (event: Categories): Award => {
      return Object.entries(event).reduce(
        (acc: { [key: string]: any }, [key, value]) => {
          if (
            key !== 'arXiv' &&
            key !== 'talk' &&
            key !== 'grant' &&
            key !== 'membership'
          ) {
            acc[key] = value;
          }
          return acc;
        },
        { award: true }
      ) as Award;
    };
    const toMembership = (event: Categories): Membership => {
      return Object.entries(event).reduce(
        (acc: { [key: string]: any }, [key, value]) => {
          if (
            key !== 'arXiv' &&
            key !== 'talk' &&
            key !== 'grant' &&
            key !== 'award'
          ) {
            acc[key] = value;
          }
          return acc;
        },
        { membership: true }
      ) as Membership;
    };

    this.$emit(
      'eventChange',
      type === 'Publication'
        ? toPublication(this.event)
        : type === 'Talk'
        ? toTalk(this.event)
        : type === 'Grant'
        ? toGrant(this.event)
        : type === 'Award'
        ? toAward(this.event)
        : toMembership(this.event)
    );
  }
}
