





















































































































































































































import { Component, Vue, Prop, Model, Mixins } from 'vue-property-decorator';
import * as firebase from 'firebase';
import { BaseVue } from '@/mixins';
import { Getter } from 'vuex-class';
import {
  Profile,
  Publication,
  Talk,
  isPublication,
  Award,
  isTalk,
  News,
  isGrant,
  Grant,
  Categories,
  Membership,
  isAward,
} from '@/store/models';

type StringCategories =
  | 'Publication'
  | 'Talk'
  | 'Grant'
  | 'Award'
  | 'Membership';

@Component
export default class EventEditor extends Mixins(BaseVue) {
  @Getter('profile', { namespace: 'user' })
  public profile!: Profile;
  public event: Categories = this.getNewEvent();
  public isDatepickerOpen = false;

  public getNewEvent(): Publication {
    return {
      // TODO Improvement
      // This is a bad design, anyway what we want to do here is to have always an external link field
      externalLinks: [''],
      headline: '',
      headlineJa: '',
      subhead: '',
      subheadJa: '',
      arXiv: '',
      on: firebase.firestore.Timestamp.now(),
    };
  }

  public get date() {
    return this.event.on
      ? this.parseDate(this.event.on.toDate())
      : this.parseDate(new Date());
  }

  public onDateSelect(value: string) {
    const date = new Date(value);
    const on = firebase.firestore.Timestamp.fromDate(date);
    this.event = { ...this.event, on };
    this.isDatepickerOpen = false;
  }

  public parseDate(targetDate: Date) {
    const [year, month, date] = [
      targetDate.getFullYear(),
      targetDate.getMonth() + 1,
      targetDate.getDate(),
    ];
    return `${year}-${('0' + month).slice(-2)}-${('0' + date).slice(-2)}`;
  }

  public onResetClick() {
    this.event = this.getNewEvent();
  }

  public async onAddClick() {
    const event = {
      ...this.event,
      // Normalize the External Links
      externalLinks: this.event.externalLinks.filter(
        (link, index) => index === 0 || link !== ''
      ),
    };
    this.event = this.getNewEvent();

    const [{ id }, allNews] = await Promise.all([
      this.$firebaseService.addEvent(event),
      this.$firebaseService.getAllNews(),
    ]);

    this.$firebaseService.modifyNews({
      id,
      status: 'New',
      displayOrder: allNews.length > 0 ? allNews[0].displayOrder + 1 : 1,
      ...((event as unknown) as any),
    });
  }

  public isPublication(event: Categories): event is Publication {
    return isPublication(event);
  }

  public isTalk(event: Categories): event is Talk {
    return isTalk(event);
  }

  public isGrant(event: Categories): event is Grant {
    return isGrant(event);
  }

  public isAward(event: Categories): event is Grant {
    return isAward(event);
  }

  public switchEvent(type: StringCategories) {
    const toPublication = (event: Categories): Publication => {
      return Object.entries(event).reduce(
        (acc: { [key: string]: any }, [key, value]) => {
          if (
            key !== 'talk' &&
            key !== 'grant' &&
            key !== 'award' &&
            key !== 'membership'
          ) {
            acc[key] = value;
          }
          return acc;
        },
        { arXiv: '' }
      ) as Publication;
    };
    const toTalk = (event: Categories): Talk => {
      return Object.entries(event).reduce(
        (acc: { [key: string]: any }, [key, value]) => {
          if (
            key !== 'arXiv' &&
            key !== 'grant' &&
            key !== 'award' &&
            key !== 'membership'
          ) {
            acc[key] = value;
          }
          return acc;
        },
        { talk: true }
      ) as Talk;
    };
    const toGrant = (event: Categories): Award => {
      return Object.entries(event).reduce(
        (acc: { [key: string]: any }, [key, value]) => {
          if (
            key !== 'arXiv' &&
            key !== 'talk' &&
            key !== 'award' &&
            key !== 'membership'
          ) {
            acc[key] = value;
          }
          return acc;
        },
        { grant: true }
      ) as Award;
    };
    const toAward = (event: Categories): Award => {
      return Object.entries(event).reduce(
        (acc: { [key: string]: any }, [key, value]) => {
          if (
            key !== 'arXiv' &&
            key !== 'talk' &&
            key !== 'grant' &&
            key !== 'membership'
          ) {
            acc[key] = value;
          }
          return acc;
        },
        { award: true }
      ) as Award;
    };
    const toMembership = (event: Categories): Membership => {
      return Object.entries(event).reduce(
        (acc: { [key: string]: any }, [key, value]) => {
          if (
            key !== 'arXiv' &&
            key !== 'talk' &&
            key !== 'grant' &&
            key !== 'award'
          ) {
            acc[key] = value;
          }
          return acc;
        },
        { membership: true }
      ) as Membership;
    };
    this.event =
      type === 'Publication'
        ? toPublication(this.event)
        : type === 'Talk'
        ? toTalk(this.event)
        : type === 'Grant'
        ? toGrant(this.event)
        : type === 'Award'
        ? toAward(this.event)
        : toMembership(this.event);
  }
}
