















































































































































































































































import { Component, Vue, Mixins } from 'vue-property-decorator';
import { BaseVue } from '../mixins';
import { Getter } from 'vuex-class';
import { Profile, Research } from '@/store/models';
import ResearchEditorModal from '@/components/ResearchEditorModal.vue';
import firebase from 'firebase';

@Component({ components: { ResearchEditorModal } })
export default class ResearchView extends Mixins(BaseVue) {
  @Getter('profile', { namespace: 'user' })
  public profile!: Profile;
  @Getter('isAuthenticated', { namespace: 'user' })
  public isAuthenticated!: boolean;
  public next: boolean = false;
  public step:
    | 'headline'
    | 'subhead'
    | 'content'
    | 'pictureURLs'
    | 'links'
    | 'none' = 'none';

  public reaseraches: Research[] = [];
  public research: Research = this.getBlankResearch();
  public editingResearch: Research = this.getBlankResearch();
  public isResearchEditorModalOpen: boolean = false;
  public unsubscribe: () => void = () => {};

  public getBlankResearch(): Research & { spaceSeparatedPicURLs: string } {
    return {
      headline: '',
      headlineJa: '',
      subhead: '',
      subheadJa: '',
      content: '',
      contentJa: '',
      pictureURLs: [],
      spaceSeparatedPicURLs: '',
      externalLinks: [''],
      on: firebase.firestore.Timestamp.now(),
    };
  }

  public transform(research: Research) {
    // TODO Not Sure why it works in real-time (Maybe it would be better to change the approach)
    const en = {
      headline: research.headline || research.headlineJa,
      subhead: research.subhead || research.subheadJa,
      content: research.content || research.contentJa,
      externalLinks: research.externalLinks,
    };
    const ja = {
      headline: research.headlineJa || research.headline,
      subhead: research.subheadJa || research.subhead,
      content: research.contentJa || research.content,
      externalLinks: research.externalLinks,
    };
    return { en, ja }[this.$language];
  }

  public onGoBack(
    step: 'headline' | 'subhead' | 'pictureURLs' | 'content' | 'links'
  ) {
    this.step = {
      headline: 'none',
      subhead: 'headline',
      content: 'subhead',
      pictureURLs: 'content',
      links: 'links',
    }[step] as 'headline' | 'subhead' | 'content' | 'pictureURLs' | 'links';
  }

  public onEditClick(research: Research) {
    this.editingResearch = research;
    this.isResearchEditorModalOpen = true;
  }

  public onSubmit(
    researchWitSpaceSeparateUrls: Research & { spaceSeparatedPicURLs: string }
  ) {
    const { spaceSeparatedPicURLs, ...rest } = researchWitSpaceSeparateUrls;
    const pictureURLs = spaceSeparatedPicURLs
      .split(/\b\s+/)
      .filter(pic => !!pic.trim());
    this.research = this.getBlankResearch();

    this.$firebaseService.addResearch({
      ...rest,
      pictureURLs,
    });
    this.step = 'none';
  }

  public async onModifyResearchClick(research: Research) {
    const res = await this.$firebaseService.modifyResearch(research);
    this.isResearchEditorModalOpen = false;
  }

  public onRemove(research: Research) {
    this.$firebaseService.removeResearch(research);
  }

  public created() {
    this.unsubscribe = this.$firebaseService.observeResearches(
      researches => (this.reaseraches = researches)
    );
  }

  public destroyed() {
    this.unsubscribe();
  }
}
