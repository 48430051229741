import * as firebase from 'firebase';

import {
  Profile,
  Education,
  AcademicPosition,
  Publication,
  Talk,
  Award,
  isPublication,
  isTalk,
  isAward,
  isEducation,
  News,
  Grant,
  isGrant,
  Research,
  Categories,
  Membership,
} from '@/store/models';

interface FirebaseConfig {
  apiKey: string;
  authDomain: string;
  databaseURL: string;
  projectId: string;
  storageBucket: string;
  messagingSenderId: string;
  appId: string;
}

/** Internal Only */
interface FirebaseProfile {
  id: string;
  email: string;
  family_name: string;
  given_name: string;
  granted_scopes: string;
  locale: string;
  name: string;
  picture: string;
  verified_email: boolean;
}

type PartialEvent<T> = Omit<T, 'externalLinks'> & {
  externalLink?: string;
  externalLinks?: string[];
};

const normalizeEvent = <T>(id: string, data: PartialEvent<T>): T => {
  const { externalLink, externalLinks, ...rest } = data;
  return {
    ...(rest as Omit<T, 'externalLinks' & 'externalLink'>),
    externalLinks:
      externalLink !== undefined
        ? [externalLink, ...(externalLinks || [])]
        : externalLinks || [],
    id,
  };
};

const snapshotToTypedArray = <T>(
  snapshot: firebase.firestore.QuerySnapshot,
  array: T[]
): T[] => {
  snapshot.forEach((result: any) =>
    array.push(normalizeEvent<T>(result.id, result.data() as PartialEvent<T>))
  );
  return array;
};

export default class FirebaseService {
  private get userTemplate(): Profile {
    return {
      id: '',
      firstName: '',
      lastName: '',
      academicPosition: '',
      photoURL: '',
      headline: '',
      email: '',
      locale: '',
      location: '',
      siteURL: '',
      googleScholarURL: '',
      about: '',
      researchmap: '',
      rimsURL: '',
      backgroundImgURLs: [],
      plasmaPhysicsLaboratoryURL: '',
      rest: [],
    };
  }

  public constructor(private firebaseConfig: FirebaseConfig) {
    firebase.initializeApp(firebaseConfig);
  }

  public onAuthStateChanged(callback: (user: firebase.User | null) => void) {
    return firebase.auth().onAuthStateChanged(user => callback(user));
  }

  public signOut() {
    return firebase.auth().signOut();
  }

  public singInWithGoogle(): Promise<{
    user: Profile;
    isNewUser: boolean;
  } | void> {
    const provider = new firebase.auth.GoogleAuthProvider();
    //   provider.addScope('https://www.googleapis.com/auth/contacts.readonly');
    firebase.auth().useDeviceLanguage();

    return firebase
      .auth()
      .signInWithPopup(provider)
      .then(result => {
        // FIXME id -> uid
        const { additionalUserInfo, user } = result;
        const profile =
          additionalUserInfo && additionalUserInfo.profile
            ? (additionalUserInfo.profile as FirebaseProfile)
            : undefined;
        if (user && profile && additionalUserInfo) {
          return {
            user: {
              id: profile.id,
              firstName: profile.given_name,
              lastName: profile.family_name,
              academicPosition: '',
              photoURL: profile.picture,
              headline: profile.given_name + ' ' + profile.family_name,
              email: profile.email,
              locale: profile.locale,
              location: '',
              siteURL: '',
              about: '',
              googleScholarURL: '',
              rimsURL: '',
              researchmap: '',
              backgroundImgURLs: [],
              plasmaPhysicsLaboratoryURL: '',
              rest: [],
            },
            isNewUser: additionalUserInfo.isNewUser,
          };
        }
      })
      .catch(error => {
        const errorCode = error.code;
        const errorMessage = error.message;
        console.error(errorCode, errorMessage);
      });
  }

  // User Service --------------------------------------------------------------------------------------------------
  public registerUser(user: Profile) {
    const firestore = firebase.firestore();
    return firestore
      .collection('users')
      .doc(user.id)
      .set({
        ...user,
        updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
        createdAt: firebase.firestore.FieldValue.serverTimestamp(),
      });
  }

  public modifyProfile(profile: Profile) {
    const firestore = firebase.firestore();
    return firestore.doc(`users/101144537552407035801`).set(profile);
  }

  public async getUser() {
    const firestore = firebase.firestore();
    const snapshot = await firestore.collection('users').get();
    const users: Profile[] = [];
    snapshot.forEach(result => users.push(result.data() as Profile));
    return users.length === 0 ? this.userTemplate : users[0];
  }

  public observeUser(callback: (user: Profile) => void) {
    const firestore = firebase.firestore();
    return firestore.collection('users').onSnapshot(snapshot => {
      const profile: Profile[] = [];
      snapshot.forEach(result => profile.push(result.data() as Profile));

      // profile rest was added on 2023/08/06 so it was not contained. After
      // the first update we can consider to remove this temporary fix
      const _profile = profile.map(p => ({ ...p, rest: p.rest || [] }));
      callback(profile.length === 0 ? this.userTemplate : _profile[0]);
    });
  }

  // Education History -------------------------------------------------------------------------------------------------
  public obtainEducationHistory(callback: (education: Education[]) => void) {
    const firestore = firebase.firestore();
    return firestore
      .collection(`users/101144537552407035801/education-history`)
      .orderBy('to', 'desc')
      .onSnapshot(snapshot => {
        const educationHistory: Education[] = [];
        snapshot.forEach(result =>
          educationHistory.push(
            normalizeEvent<Education>(result.id, result.data() as any)
          )
        );
        callback(educationHistory);
      });
  }

  public async getEducationHistory() {
    const firestore = firebase.firestore();
    const snapshot = await firestore
      .collection(`users/101144537552407035801/education-history`)
      .orderBy('to', 'desc')
      .get();
    const educationHistory: Education[] = [];
    return snapshotToTypedArray(snapshot, educationHistory);
  }

  public obtainAcademicPositions(
    callback: (education: AcademicPosition[]) => void
  ) {
    const firestore = firebase.firestore();
    return firestore
      .collection(`users/101144537552407035801/academic-positions`)
      .orderBy('to', 'desc')
      .onSnapshot(snapshot => {
        const academicPositions: AcademicPosition[] = [];
        snapshot.forEach(result =>
          academicPositions.push(
            normalizeEvent<AcademicPosition>(
              result.id,
              result.data() as PartialEvent<AcademicPosition>
            )
          )
        );
        callback(academicPositions);
      });
  }

  public async getAcademicPositions() {
    const firestore = firebase.firestore();
    const snapshot = await firestore
      .collection(`users/101144537552407035801/academic-positions`)
      .orderBy('to', 'desc')
      .get();
    const academicPositions: AcademicPosition[] = [];
    return snapshotToTypedArray(snapshot, academicPositions);
  }

  public addEducationOrAcademicPosition(
    educationOrAcademicPosition: Education | AcademicPosition
  ) {
    const firestore = firebase.firestore();
    return firestore
      .collection(
        `users/101144537552407035801/${
          isEducation(educationOrAcademicPosition)
            ? 'education-history'
            : 'academic-positions'
        }`
      )
      .add({
        ...educationOrAcademicPosition,
        createdAt: firebase.firestore.FieldValue.serverTimestamp(),
        updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
      });
  }

  public modifyEducationOrAcademicPosition(
    educationOrAcademicPosition: Education | AcademicPosition
  ) {
    const firestore = firebase.firestore();
    return firestore
      .doc(
        `users/101144537552407035801/${
          isEducation(educationOrAcademicPosition)
            ? 'education-history'
            : 'academic-positions'
        }/${educationOrAcademicPosition.id!}`
      )
      .set({
        ...educationOrAcademicPosition,
        updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
      });
  }

  public removeEducationOrAcademicPosition(
    educationOrAcademicPosition: Education | AcademicPosition
  ) {
    const firestore = firebase.firestore();
    return firestore
      .doc(
        `users/101144537552407035801/${
          isEducation(educationOrAcademicPosition)
            ? 'education-history'
            : 'academic-positions'
        }/${educationOrAcademicPosition.id!}`
      )
      .delete();
  }

  // Pdf Header --------------------------------------------------------------------------------------------------------------

  public obtainPdfHeader(callback: (headline: string) => void) {
    const firestore = firebase.firestore();
    return firestore
      .doc(`users/101144537552407035801/pdf/header`)
      .onSnapshot(doc => {
        const data = doc.data() as { headline: string } | undefined;
        if (data) {
          callback(data.headline);
        }
      });
  }

  public async getPdfHeader() {
    const firestore = firebase.firestore();
    const doc = await firestore
      .doc(`users/101144537552407035801/pdf/header`)
      .get();
    const data = doc.data() as { headline: string } | undefined;
    return data ? data.headline : '';
  }

  public modifyPdfHeader(headline: string) {
    const firestore = firebase.firestore();
    return firestore.doc(`users/101144537552407035801/pdf/header`).set({
      headline,
      updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
    });
  }

  public removePdfHeader(header: { headline: string }) {
    const firestore = firebase.firestore();
    return firestore.doc(`users/101144537552407035801/pdf/header`).delete();
  }

  // News --------------------------------------------------------------------------------------------------------------

  public async getAllNews() {
    const firestore = firebase.firestore();
    const result = await firestore
      .collection(`users/101144537552407035801/news`)
      .orderBy('displayOrder', 'desc')
      .get();
    const allNews: News[] = [];
    result.forEach(news =>
      allNews.push(normalizeEvent(news.id, news.data() as News))
    );
    return allNews;
  }

  public obtainNews(callback: (allNews: News[]) => void) {
    const firestore = firebase.firestore();
    return firestore
      .collection(`users/101144537552407035801/news`)
      .orderBy('displayOrder', 'desc')
      .onSnapshot(snapshot => {
        const allNews: News[] = [];
        snapshot.forEach(result =>
          allNews.push(normalizeEvent(result.id, result.data() as News))
        );
        callback(allNews);
      });
  }

  public addNews(news: News) {
    const firestore = firebase.firestore();
    return firestore.collection(`users/101144537552407035801/news`).add({
      ...news,
      createdAt: firebase.firestore.FieldValue.serverTimestamp(),
      updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
    });
  }

  public modifyNews(news: News) {
    const firestore = firebase.firestore();
    return firestore.doc(`users/101144537552407035801/news/${news.id!}`).set({
      ...news,
      updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
    });
  }

  public removeNews(news: News) {
    const firestore = firebase.firestore();
    return firestore
      .doc(`users/101144537552407035801/news/${news.id!}`)
      .delete();
  }

  // News --------------------------------------------------------------------------------------------------------------

  public observeResearches(callback: (research: Research[]) => void) {
    const firestore = firebase.firestore();
    return firestore
      .collection(`users/101144537552407035801/researches`)
      .orderBy('updatedAt', 'desc')
      .onSnapshot(snapshot => {
        const researches: Research[] = [];
        snapshot.forEach(result =>
          researches.push(
            normalizeEvent<Research>(result.id, result.data() as any)
          )
        );
        callback(researches);
      });
  }

  public addResearch(research: Research) {
    const firestore = firebase.firestore();
    return firestore.collection(`users/101144537552407035801/researches`).add({
      ...research,
      createdAt: firebase.firestore.FieldValue.serverTimestamp(),
      updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
    });
  }

  public modifyResearch(research: Research) {
    const firestore = firebase.firestore();
    return firestore
      .doc(`users/101144537552407035801/researches/${research.id!}`)
      .set({
        ...research,
        updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
      });
  }

  public removeResearch(research: Research) {
    const firestore = firebase.firestore();
    return firestore
      .doc(`users/101144537552407035801/researches/${research.id!}`)
      .delete();
  }

  // Publication Talks Award -------------------------------------------------------------------------------------------
  public obtainPublications(callback: (publications: Publication[]) => void) {
    const firestore = firebase.firestore();
    return firestore
      .collection(`users/101144537552407035801/publications`)
      .orderBy('on', 'desc')
      .onSnapshot(snapshot => {
        const publications: Publication[] = [];
        snapshot.forEach(result =>
          publications.push(
            normalizeEvent<Publication>(
              result.id,
              result.data() as PartialEvent<Publication>
            )
          )
        );
        callback(publications);
      });
  }

  public async getPublications() {
    const firestore = firebase.firestore();
    const snapshot = await firestore
      .collection(`users/101144537552407035801/publications`)
      .orderBy('on', 'desc')
      .get();

    const publications: Publication[] = [];
    return snapshotToTypedArray(snapshot, publications);
  }

  public obtainTalks(callback: (talks: Talk[]) => void) {
    const firestore = firebase.firestore();
    return firestore
      .collection(`users/101144537552407035801/talks`)
      .orderBy('on', 'desc')
      .onSnapshot(snapshot => {
        const talks: Talk[] = [];
        snapshot.forEach(result =>
          talks.push(
            normalizeEvent<Talk>(result.id, result.data() as PartialEvent<Talk>)
          )
        );
        callback(talks);
      });
  }

  public async getTalks() {
    const firestore = firebase.firestore();
    const snapshot = await firestore
      .collection(`users/101144537552407035801/talks`)
      .orderBy('on', 'desc')
      .get();

    const talks: Talk[] = [];
    return snapshotToTypedArray(snapshot, talks);
  }

  public obtainGrants(callback: (talks: Grant[]) => void) {
    const firestore = firebase.firestore();
    return firestore
      .collection(`users/101144537552407035801/grants`)
      .orderBy('on', 'desc')
      .onSnapshot(snapshot => {
        const grants: Grant[] = [];
        snapshot.forEach(result =>
          grants.push(
            normalizeEvent<Grant>(
              result.id,
              result.data() as PartialEvent<Grant>
            )
          )
        );
        callback(grants);
      });
  }

  public async getGrants() {
    const firestore = firebase.firestore();
    const snapshot = await firestore
      .collection(`users/101144537552407035801/grants`)
      .orderBy('on', 'desc')
      .get();

    const grants: Grant[] = [];
    return snapshotToTypedArray(snapshot, grants);
  }

  public obtainAwards(callback: (talks: Award[]) => void) {
    const firestore = firebase.firestore();
    return firestore
      .collection(`users/101144537552407035801/awards`)
      .orderBy('on', 'desc')
      .onSnapshot(snapshot => {
        const awards: Award[] = [];
        snapshot.forEach(result =>
          awards.push(
            normalizeEvent<Award>(
              result.id,
              result.data() as PartialEvent<Award>
            )
          )
        );
        callback(awards);
      });
  }

  public async getAwards() {
    const firestore = firebase.firestore();
    const snapshot = await firestore
      .collection(`users/101144537552407035801/awards`)
      .orderBy('on', 'desc')
      .get();

    const awards: Award[] = [];
    return snapshotToTypedArray(snapshot, awards);
  }

  public obtainMemberships(callback: (memberships: Membership[]) => void) {
    const firestore = firebase.firestore();
    return firestore
      .collection(`users/101144537552407035801/memberships`)
      .orderBy('on', 'desc')
      .onSnapshot(snapshot => {
        const membership: Membership[] = [];
        snapshot.forEach(result =>
          membership.push(
            normalizeEvent<Membership>(
              result.id,
              result.data() as PartialEvent<Membership>
            )
          )
        );
        callback(membership);
      });
  }

  public async getMemberships() {
    const firestore = firebase.firestore();
    const snapshot = await firestore
      .collection(`users/101144537552407035801/memberships`)
      .orderBy('on', 'desc')
      .get();

    const memberships: Membership[] = [];
    return snapshotToTypedArray(snapshot, memberships);
  }

  public addEvent(event: Categories) {
    const firestore = firebase.firestore();
    return firestore
      .collection(`users/101144537552407035801/${this.getResourceNm(event)}`)
      .add({
        ...event,
        createdAt: firebase.firestore.FieldValue.serverTimestamp(),
        updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
      });
  }

  public modifyEvent(event: Categories) {
    const firestore = firebase.firestore();
    return firestore
      .doc(
        `users/101144537552407035801/${this.getResourceNm(event)}/${event.id!}`
      )
      .set({
        ...event,
        updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
      });
  }

  public removeEvent(event: Categories) {
    const firestore = firebase.firestore();
    return firestore
      .doc(
        `users/101144537552407035801/${this.getResourceNm(event)}/${event.id!}`
      )
      .delete();
  }

  public getResourceNm(
    arg: Categories
  ): 'publications' | 'talks' | 'grants' | 'awards' | 'memberships' {
    return isPublication(arg)
      ? 'publications'
      : isTalk(arg)
      ? 'talks'
      : isGrant(arg)
      ? 'grants'
      : isAward(arg)
      ? 'awards'
      : 'memberships';
  }
}
