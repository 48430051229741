import { Vue, Component } from 'vue-property-decorator';
import translations from './tranlations';
import firebase from 'firebase';

@Component
export class BaseVue extends Vue {
  public get $language() {
    const { query } = this.$route || { query: { lang: 'en' } };
    return query.lang === 'ja' ? query.lang : 'en';
  }

  public get $translations() {
    return translations[this.$language];
  }

  public $parseTimestamp(
    timestamp?: firebase.firestore.Timestamp,
    format?: 'YYYY-MM-DD' | 'YYYY-MM-DD HH:MM',
  ) {
    const parseDate = (
      targetFormat: 'YYYY-MM-DD' | 'YYYY-MM-DD HH:MM',
      targetTimestamp: firebase.firestore.Timestamp,
    ) => {
      const targetDate = targetTimestamp.toDate();
      const [year, month, date, hours, minutes] = [
        targetDate.getFullYear(),
        targetDate.getMonth() + 1,
        targetDate.getDate(),
        targetDate.getHours(),
        targetDate.getMinutes(),
      ];
      const dateWithoutTime = `${year}-${('0' + month).slice(-2)}-${(
        '0' + date
      ).slice(-2)}`;
      const time = `${('0' + hours).slice(-2)}:${('0' + minutes).slice(-2)}`;
      return targetFormat === 'YYYY-MM-DD'
        ? dateWithoutTime
        : `${dateWithoutTime} ${time}`;
    };

    return parseDate(
      format || 'YYYY-MM-DD',
      timestamp || firebase.firestore.Timestamp.now(),
    );
  }
}
