



















































































































































































































































import {
  Component,
  Vue,
  Prop,
  Model,
  Mixins,
  Watch,
} from 'vue-property-decorator';
import * as firebase from 'firebase';
import { BaseVue } from '@/mixins';
import { Getter } from 'vuex-class';
import { Profile } from '@/store/models';

@Component
export default class ProfileEditor extends Mixins(BaseVue) {
  @Getter('profile', { namespace: 'user' })
  public profile!: Profile;
  public newProfile!: Profile;
  public snackbar = false;

  public async onSaveClick() {
    const result = await this.$firebaseService.modifyProfile(this.newProfile);
    this.snackbar = true;
  }

  @Watch('profile', { immediate: true })
  public onProfileChange() {
    this.newProfile = this.profile;
  }
}
